<template>
  <b-card>
    <!-- form -->
    <b-form>
      <b-row class="mb-1">
        <!-- Per Page -->
        <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
          <h4>Manage Multi-Factor-Auth</h4>
        </b-col>

        <!-- Search -->
        <b-col cols="12" md="6">
          <div class="d-flex align-items-center justify-content-end">
            <b-button variant="primary" v-b-modal.modal-select-type>
              <span class="text-nowrap">Add Method</span>
            </b-button>
          </div>
        </b-col>
      </b-row>

      <b-row>
        <b-col sm="12" md="6" lg="6" v-for="mfa in methods" :key="mfa.id">
          <b-card no-body>
            <b-card-body
              class="d-flex align-items-center"
              style="background-color: #1a2029 !important; border-radius: 7px"
            >
              <b-avatar :variant="`light-${mfa.enabled ? 'success' : 'primary'}`" size="45">
                <font-awesome-icon size="2x" :icon="iconOfMfaType(mfa.type)" />
              </b-avatar>

              <div class="truncate ml-1 w-50">
                <h4 class="mb-25 font-weight-bolder">
                  {{ mfa.name || title(mfa.type) }}
                </h4>
                <div class="text-truncate" v-b-tooltip.hover.bottom="mfa.display">{{ mfa.display }}</div>
              </div>

              <a v-if="mfa.enabled" class="ml-auto" href="javascript:" @click="disableMfa(mfa)" v-b-tooltip.hover.left="'Disable this MFA'">
                <b-avatar :variant="`light-success`" size="35">
                  <font-awesome-icon size="1x" icon="fa-solid fa-power-off" />
                </b-avatar>
              </a>
              <div v-else class="ml-auto">
                <a @click.pr.prevent="deleteMfa(mfa)" v-b-tooltip.hover.left="'Delete this MFA'">
                  <b-avatar :variant="`light-primary`" size="35">
                    <font-awesome-icon size="1x" icon="fa-solid fa-times" />
                  </b-avatar>
                </a>
                <a @click.prevent="enableMfa(mfa)" class="ml-1" v-b-tooltip.hover.left="'Enable this MFA'">
                  <b-avatar :variant="`light-primary`" size="35">
                    <font-awesome-icon size="1x" icon="fa-solid fa-refresh" />
                  </b-avatar>
                </a>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-form>

    <b-modal
      id="modal-select-type"
      ref="modal-select-type"
      title="Select method type"
      cancel-variant="outline-secondary"
      centered
      no-stacking
      @ok="onTypeSelect"
      no-close-on-backdrop
    >
      <b-form>
        <b-form-group label="Choose the type of mfa method you would like to create." label-for="select-select-type">
          <v-select
            id="select-select-type"
            v-model="method.type"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="methodTypes"
            :selectable="(option) => !option.value.includes('select_value')"
            :clearable="false"
            :reduce="(val) => val.value"
            @input="(val) => (method.type = val)"
          />
        </b-form-group>
      </b-form>
    </b-modal>

    <b-modal
      id="modal-method-info"
      ref="modal-method-info"
      title="Enter mfa information"
      cancel-variant="outline-secondary"
      centered
      no-stacking
      @ok="createMfa"
      no-close-on-backdrop
    >
      <b-form>
        <b-form-group label="Custom Name" label-for="input-method-name">
          <b-form-input
            required
            id="input-method-name"
            v-model="method.name"
            placeholder="Work Discount Account"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          v-if="method.type === 'email'"
          label="Email Address (leave empty to use email linked to your account)"
          label-for="input-method-m-email"
        >
          <b-form-input
            type="email"
            id="input-method-m-email"
            v-model="method.metadata.email"
            placeholder="ishan@intellectualpoint.com"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          v-else-if="method.type === 'sms'"
          label="Phone Number (same format as placeholder)"
          label-for="input-method-m-phoneNumber"
        >
          <b-form-input
            type="tel"
            id="input-method-m-phoneNumber"
            v-model="method.metadata.phoneNumber"
            placeholder="+17035543827"
          ></b-form-input>
        </b-form-group>
        <b-form-group v-else-if="method.type === 'discord'" label="Discord Id" label-for="input-method-m-discord">
          <b-form-input
            type="text"
            id="input-method-m-discord"
            v-model="method.metadata.discordId"
            placeholder="724630635771199548"
          ></b-form-input>
        </b-form-group>
        <b-form-group v-else-if="method.type === 'override'" label="Override Code" label-for="input-method-m-code">
          <b-form-input id="input-method-m-code" v-model="method.metadata.code" placeholder="Z9784"></b-form-input>
        </b-form-group>
      </b-form>
    </b-modal>

    <b-modal
      id="modal-display-ts"
      ref="modal-display-ts"
      title="Scan this QR code"
      cancel-variant="outline-secondary"
      centered
      no-stacking
      ok-only
      @ok="enableMfa(method, false)"
      size="xs"
      no-close-on-backdrop
    >
      <b-form>
        <b-form-group label="Secret" label-for="input-secret">
          <b-form-input disabled id="input-secret" v-model="method.metadata.secret"></b-form-input>
        </b-form-group>

        <img class="w-100" :src="`https://api.qrserver.com/v1/create-qr-code/?size=256x256&data=${this.qrUrl}&format=svg`" />
      </b-form>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BInputGroup,
  BInputGroupAppend,
  BCardBody,
  BAvatar,
  BModal,
  VBTooltip,
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import ToastificationContent from '@core/components/toastification/ToastificationContent';
import { title } from '@core/utils/filter';
import useJwt from '@/auth/jwt/useJwt';
import vSelect from 'vue-select';

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupAppend,
    BCard,
    BCardBody,
    BAvatar,
    vSelect,
    Ripple,
    VBTooltip,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      processing: false,

      methodTypes: [
        { value: 'time_secret', label: 'TFA' },
        { value: 'email', label: 'Email' },
        { value: 'sms', label: 'SMS' },
        { value: 'discord', label: 'Discord' },
        { value: 'override', label: 'Override' },
      ],

      method: { type: 'time_secret', metadata: {} },

      methods: [],
    };
  },
  computed: {
    passwordToggleIconOld() {
      return this.passwordFieldTypeOld === 'password' ? 'EyeIcon' : 'EyeOffIcon';
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon';
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === 'password' ? 'EyeIcon' : 'EyeOffIcon';
    },
    qrUrl() {
      const name = this.method.name === 'Time Secret' ? `@${this.userData.username}` : this.method.name;
      return encodeURI(`otpauth://totp/${encodeURI(name)}?secret=${this.method.metadata.secret}&issuer=Management`);
    },
  },
  methods: {
    iconOfMfaType(type) {
      switch (type) {
        case 'time_secret':
          return 'fa-solid fa-clock';
        case 'email':
          return 'fa-solid fa-envelope';
        case 'sms':
          return 'fa-solid fa-comment-sms';
        case 'discord':
          return 'fa-brands fa-discord';
        case 'override':
          return 'fa-solid fa-lock';
        default:
          return 'fa-solid fa-user-secret';
      }
    },
    loadMethods() {
      this.processing = true;

      this.$http
        .get(`auth/mfa-methods?disabled=true&rich=true`)
        .then((response) => {
          this.methods = response.data;

          console.log(this.methods);
        })
        .catch((error) => {
          this.processing = false;
          console.log(error);

          if (error.response) {
            let d = error.response.data;

            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Failed to retrieve methods',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: d.message,
              },
            });
            return;
          }

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to retrieve methods',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: `An unknown error has occurred.`,
            },
          });
        });
    },
    addMfaMethod() {},
    async enableMfa(mfa, requested = true) {
      if(mfa.enabled) return;

      const doSubmit = async () => {
        const { value: code } = await this.$swal.fire({
          title: 'Enter the verification code you received',
          input: 'text',
          inputLabel: 'Verification Code',
          // inputValue: inputValue,
          showCancelButton: true,
          inputValidator: (value) => {
            if (!value) {
              return 'You need to write something!';
            }
          },
        });

        if (!code) return;

        useJwt
          .enableMfaMethod({ method: mfa.id, code })
          .then((response) => {
            const { data } = response;
            console.log(data);

            this.loadMethods();
          })
          .catch((err) => {
            this.enableMfa(mfa, false);
            console.error(`failed to enable mfa`, err);
          });
      };

      if (!requested) {
        return doSubmit();
      } else {
        useJwt.requestMfaCode({ method: mfa.id }).then(async (_requested) => {
          console.log(`_requested`, _requested);
          return doSubmit();
        });
      }
    },
    async disableMfa(mfa) {
      if(!mfa.enabled) return;

      useJwt
        .requestMfaCode({ method: mfa.id })
        .then(async (_requested) => {
          console.log(`_requested`, _requested);

          const getCode = () => {
            return this.$swal.fire({
              title: 'Enter the verification code you received',
              input: 'text',
              inputLabel: 'Verification Code',
              // inputValue: inputValue,
              showCancelButton: true,
              inputValidator: (value) => {
                if (!value) {
                  return 'You need to write something!';
                }
              },
            });
          };

          const { value: code } = await getCode();

          useJwt
            .disableMfaMethod({ method: mfa.id, code })
            .then((response) => {
              this.loadMethods();
            })
            .catch((err) => {
              console.error(`failed to disable mfa`, err);
            });
        })
        .catch((err) => {
          console.error(`failed to request mfa code`, err);
        });
    },
    async deleteMfa(mfa) {
      if(mfa.enabled) return;

      const confirmDeletion = () => {
        return this.$swal.fire({
          title: 'Are you sure?',
          text: 'This operation cannot be undone.',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Delete',
        });
      };

      const confirmed = await confirmDeletion();

      if(confirmed.value) {
        this.$swal({
          icon: 'success',
          title: 'Deleted!',
          text: 'Your MFA has been deleted.',
          customClass: {
            confirmButton: 'btn btn-success',
          },
        });
        useJwt
          .deleteMfaMethod({ data: { method: mfa.id } })
          .then((response) => {
            this.loadMethods();
          })
          .catch((err) => {
            this.$swal({
              icon: 'error',
              title: 'Deletion failed!',
              html: 'We\'re unable to delete this MFA method.',
              footer: 'Please contact our &nbsp; <a href="mailto:technology.services@intellectualpoint.com\"><i class="feather icon-send"></i> Customer Support</a></small>',
              customClass: {
                confirmButton: 'btn btn-danger',
              },
            });
            console.error(`failed to disable mfa`, err);
          });
      }
    },
    onTypeSelect() {
      console.log(`exec select`, this.method.type);
      this.$refs['modal-method-info'].show();
    },
    createMfa() {
      console.log(`initate create`);

      const pl = {
        type: this.method.type,
        name: this.method.name,
        metadata: this.method.metadata,
      };

      useJwt
        .createMfaMethod(pl)
        .then((response) => {
          const { data: mfa } = response;
          this.loadMethods();

          this.method = mfa;

          if (mfa.type !== 'time_secret') {
            return this.enableMfa(mfa, false);
          }

          this.$refs['modal-display-ts'].show();
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
  mounted() {
    this.loadMethods();
  },
  setup() {
    return { title };
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
